module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"posts":"/opt/build/repo/src/components/posts-layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"adrien.codes","short_name":"adrien.codes","start_url":"/","background_color":"#343a40","theme_color":"#343a40","display":"minimal-ui","icon":"src/images/me.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
